import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi there!
    </Text>
    <p>{`Director of Product Management at `}<a parentName="p" {...{
        "href": "https://www.optimizely.com/"
      }}>{`Optimizely`}</a>{`.`}</p>
    <p>{`Previously I’ve been in Product at `}<a parentName="p" {...{
        "href": "https://conductor.com"
      }}>{`Conductor`}</a>{`, `}<a parentName="p" {...{
        "href": "https://gatsbyjs.com"
      }}>{`Gatsby`}</a>{` `}{`&`}{` `}<a parentName="p" {...{
        "href": "https://getflywheel.com"
      }}>{`Flywheel`}</a>{`, Design and Engineering at `}<a parentName="p" {...{
        "href": "https://chargify.com"
      }}>{`Chargify`}</a>{`, and co-founded `}<a parentName="p" {...{
        "href": "https://campgroundbooking.com"
      }}>{`Campground Booking`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "/about"
      }}>{`Learn more`}</a>{` or `}<a parentName="p" {...{
        "href": "mailto:hire@boborchard.com"
      }}>{`Contact me`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      